<template>
    <b-container id="container" class="max-100">

        <!-- FORM TO SUBMIT EMAIL ADDRESS -->
        <b-row class="max-100" v-if="showemailsubmit === 'yes'">
            <b-col cols="1"></b-col>
            <b-col cols="10">
                <div id="go-back-button" @click.prevent="goBack">
                    <font-awesome-icon icon="arrow-alt-circle-left"></font-awesome-icon>
                </div>

                <div class="password-reset-instructions">
                    Please provide your email address of record. We will send you a
                    reset code.
                </div>
                <b-row class="errormsg max-100" align-h="center" v-if="error" :error="error">{{ error }}</b-row>

                <!-- CREDENTIALS FORM -->
                <form id="password-reset-form" @submit.prevent="codeRequestSubmit">
                    <div>
                        <input type="text" id="email-address"
                            placeholder="youremail@address.com" v-model="emailaddress">
                    </div>
                    <div >
                        <input id="submit" type="submit" value="REQUEST CODE">
                    </div>

                </form>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>


        <!-- FORM TO ENTER CODE RECEIVED BY EMAIL  -->
        <b-row class="max-100" v-if="showcodesubmit === 'yes'">
            <b-col cols="1"></b-col>
            <b-col cols="10">
                <div class="password-reset-instructions">
                    Please check your email and then return to this
                    app to enter the code below.
                </div>
                <b-row class="errormsg max-100" align-h="center" v-if="error" :error="error">{{ error }}</b-row>

                <!-- CODE FORM -->
                <form id="code-submit-form" @submit.prevent="codeSubmit">
                    <div>
                        <input type="text" id="reset-code"
                            placeholder="000000" v-model="resetcode">
                    </div>
                    <div >
                        <input id="code-submit" type="submit" value="ENTER CODE">
                    </div>

                </form>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>

        <!-- FORM TO ENTER PASSWORD  -->
        <b-row class="max-100" v-if="showpasswordsubmit === 'yes'">
            <b-col cols="1"></b-col>
            <b-col cols="10">
                <div class="password-reset-instructions">
                    Please enter your new password.
                </div>
                <div class="password-requirements">
                    <div v-if="passnotgood === 'no'">New password must have:</div>
                    <div v-if="passnotgood === 'yes'" class="notgood">New password must have:</div>
                    <ul>
                        <li>
                            <span v-if="passchar === 'yes' || passnotgood === 'no'">8 or more characters</span>
                            <span v-if="passchar === 'no' && passnotgood === 'yes'" class="notgood">8 or more characters</span>
                            <span v-if="passchar === 'yes'" class="good"> GOOD</span>
                        </li>
                        <li>
                            <span v-if="passdigit === 'yes' || passnotgood === 'no'">At least 1 number</span>
                            <span v-if="passdigit === 'no' && passnotgood === 'yes'" class="notgood">At least 1 number</span>
                            <span v-if="passdigit === 'yes'" class="good"> GOOD</span>
                        </li>
                        <li>
                            <span v-if="passsymbol === 'yes' || passnotgood === 'no'">1 symbol: @ # $ & * !</span>
                            <span v-if="passsymbol === 'no' && passnotgood === 'yes'" class="notgood">1 symbol: @ # $ & * !</span>
                            <span v-if="passsymbol === 'yes'" class="good"> GOOD</span>
                        </li>
                    </ul>
                </div>
                <b-row class="errormsg max-100" align-h="center" v-if="error" :error="error">{{ error }}</b-row>


                <!-- FORM TO ENTER CODE -->
                <form id="password-submit-form" @submit.prevent="passwordSubmit">
                    <div>
                        <input type="text" id="new-password" @input="checkPassword"
                            placeholder="new password" v-model="newpassword">
                    </div>
                    <div >
                        <input id="password-submit" type="submit" value="SUBMIT NEW PASSWORD">
                    </div>

                </form>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>


    </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowAltCircleLeft)

export default {
    name: 'password',

    computed: {
      ...mapGetters({
          error: 'password/error',
          passwordtoken: 'password/passwordtoken',
          showemailsubmit: 'password/showemailsubmit',
          showcodesubmit: 'password/showcodesubmit',
          showpasswordsubmit: 'password/showpasswordsubmit',
          redirecttosignin: 'password/redirecttosignin',
          passchar: 'password/passchar',
          passdigit: 'password/passdigit',
          passsymbol: 'password/passsymbol',
          passnotgood: 'password/passnotgood',

      }),

    },

    data() {
        return {
            emailaddress: '',
            resetcode: '',
            newpassword: '',
        }
    },

    methods: {
        ...mapActions({
            submitEmailAddress: 'password/submitEmailAddress',
            submitCode: 'password/submitCode',
            submitPassword: 'password/submitPassword',
            setError: 'password/setError',
            setAuthError: 'auth/setError',
            setSuccessMsg: 'auth/setSuccessMsg',
            resetRedirect: 'password/resetRedirect',
            setPasschar: 'password/setPasschar',
            setPassdigit: 'password/setPassdigit',
            setPasssymbol: 'password/setPasssymbol',
            setPassnotgood: 'password/setPassnotgood',
            expired: 'password/expired',
        }),

        goBack() {
            this.setError(null)
            this.$router.replace({
                name: 'signin',
            })
        },

        codeRequestSubmit() {
            if(this.emailaddress === '') {
                this.setError("No email address provided.");
            } else {
                // check email format
                if(this.validateEmail(this.emailaddress)) {
                    this.setError(null)
                    this.submitEmailAddress(this.emailaddress).then(() => {
                        if(this.redirecttosignin === 'yes') {
                            this.resetRedirect().then(() => {
                                this.setAuthError('There was a problem.')
                                this.$router.replace({
                                    name: 'signin',
                                })

                            })
                        } else {
                            //

                        }

                    })//.catch((e) => { console.log(e); });

                } else {
                    this.setError('Email is not in acceptable format.');

                }

            }

        },

        codeSubmit() {
            if(this.resetcode === '') {
                this.setError("No code was provided.");
            } else {
                this.submitCode(this.resetcode).then(() => {
                    if(this.redirecttosignin === 'yes' && this.expired === 'yes') {
                        this.resetRedirect().then(() => {
                            this.setAuthError('The code was expired, try again.')
                            this.$router.replace({
                                name: 'signin',
                            })

                        })

                    } else if(this.redirecttosignin === 'yes') {
                        this.resetRedirect().then(() => {
                            this.setAuthError('There was a problem.')
                            this.$router.replace({
                                name: 'signin',
                            })

                        })

                    } else {
                        //nothing
                    }

                })//.catch((e) => { console.log(e); });

            }

        },

        passwordSubmit() {
            if(this.newpassword === '') {
                this.setError("No password was provided.");
            } else {
                this.setError(null);
                document.getElementById('new-password').setAttribute('type','password');
                this.submitPassword(this.newpassword).then(() => {
                    if(this.redirecttosignin === 'yes' && !this.error && this.passnotgood === 'no') {
                        this.resetRedirect().then(() => {
                            this.setSuccessMsg('Log in with your new password.')
                            this.$router.replace({
                                name: 'signin',
                            })

                        })//.catch((e) => { console.log(e); });

                    } else if (this.passnotgood === 'yes') {
                        document.getElementById('new-password').setAttribute('type','text');
                    } else {
                        this.resetRedirect().then(() => {
                            this.setError(null);
                            this.setAuthError('There was a problem.')
                            this.$router.replace({
                                name: 'signin',
                            })

                        })//.catch((e) => { console.log(e); });
                    }

                })//.catch((e) => { console.log(e); });

            }

        },

        checkPassword: function(event) {
            var reallength;
            var spaces;
            if(this.hasWhiteSpace(event.target.value)) {
                this.setError('Spaces will be ignored.')
                spaces = event.target.value.split(" ").length-1;
                reallength = event.target.value.length - spaces;
            } else {
                reallength = event.target.value.length;
                this.setError(null)

            }

            if(reallength >= 8) {
                this.setPasschar('yes');
            } else if(reallength == 0) {
                this.setPasschar('no');
                this.setPassdigit('no');
                this.setPasssymbol('no');
            } else {
                this.setPasschar('no');
            }

            var digit_pattern = /[0-9]/g;
            if(event.target.value.match(digit_pattern) != null) {
                this.setPassdigit('yes');
            } else {
                this.setPassdigit('no');
            }

            var symbol_pattern = /[@#$&*!]/g;
            if(event.target.value.match(symbol_pattern) != null) {
                this.setPasssymbol('yes');
            } else {
                this.setPasssymbol('no');
            }

            if(this.passchar === 'yes' && this.passdigit === 'yes' && this.passsymbol === 'yes') {
                this.setPassnotgood('no');
            }


        },

        hasWhiteSpace(str) {
          return str.indexOf(' ') >= 0;
        },

        validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);

        },

    }

}

</script>

<style scoped>

    #container {
      padding: 0 0 0 0;
      height: 100vh;
    }
    .max-100 {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        min-width: 100%;
        max-width: 100%;
    }


    .password-reset-instructions {
        margin-top: 2em;
        text-align: center;
        background-color: #0f70b7;
        color: white;
        padding: 0.5em;
    }

    .errormsg {
        margin-bottom: 2vh;
        color: red;
        font-weight: bold;
    }

    #password-reset-form, #code-submit-form, #password-submit-form {
        max-width: 300px;
        max-width: 90vw;

    }

    #email-address, #new-password {
        width: 100%;
        padding: 6px;
        padding-top: 1em;

        min-height: 30px;
        max-height: 50px;
        margin-bottom: 10px;
        border-left: none;
        border-top: none;
        border-right: none;
        border-bottom: 2px solid Gray;
    }

    #reset-code {
        position: relative;
        transform: translateX(-50%);
        text-align: center;
        width: 50%;
        padding: 6px;
        padding-top: 1em;
        margin-left: 50%;
        font-size: 2em;
        font-family: monospace;
        min-height: 30px;
        max-height: 50px;
        margin-bottom: 10px;
        border-left: none;
        border-top: none;
        border-right: none;
        border-bottom: 2px solid Gray;

    }

    #go-back-button {
        margin-top: 0.5em;
        font-size: 2em;
        color: #0f70b7;
    }

    #go-back-button:active {
        margin-top: 0.5em;
        font-size: 2em;
        color: #0f70b7;
        transform: matrix(1.1,0,0,1.1,12,0);
    }

    #submit, #code-submit, #password-submit {
        background-color: transparent;
        color: Gray;
        margin-top: 20px;
        padding: .25em;
        border: 1px solid LightGray;
        border-radius: .5em;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    #submit:active, #code-submit:active, #password-submit:active {
        margin-left: 50%;
        transform: translate(-50%,3px);
    }


    .good {
        color: #00FA9A;
        font-weight: bold;
    }

    .notgood {
        color: red;
        font-weight: bold;

    }
</style>
